import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal } from 'react-bootstrap';
import { EnrollmentFormCol, EnrollmentFormRow } from "./styles/Enrollment.styled";
import { RadioBox } from "./RadioBox";
import { FormError } from "./FormError";
import { Button } from "./styles/Button.styled";
import { serviceOptions } from "../common";

export const QuestionModal = ({ show, handleClose, handleConfirm, defaultValue }: {
    show: boolean;
    handleClose: Function;
    handleConfirm: Function;
    defaultValue?: any;
}) => {
    const { t } = useTranslation('usage');
    const [ selectedProduct, setSelectedProduct ] = useState<{label:string;value:number}|undefined>(undefined);
    const [ errorFields, setErrorFields ] = useState<any>({});

    useEffect(() => {
        if (!show) return;

        if (defaultValue && defaultValue.product) {
            const defaultProduct = serviceOptions.find((val: any) => val.value === parseInt(defaultValue.product));
            if (defaultProduct) {
                setSelectedProduct(defaultProduct);
            }
        }
    }, [defaultValue, show])

    // const validate = () => {
    //     let isValid = true
    //     let newErrorFields: any = {}
    //     if (!selectedProduct) {
    //         newErrorFields['product'] = t('Please select one of these options.')
    //         isValid = false
    //     }
    //     setErrorFields(newErrorFields)
    //     return isValid
    // }

    // const confirm = () => {
        // if (!validate()) return;
        // let res = `solar=${selectedProduct?.value}`;
        // handleConfirm(res);
    // }

    return (
        <Modal size="lg" show={show} onHide={() => {handleClose()}}>
            <Modal.Header closeButton>
                <Modal.Title>{t('Enter your details for a free solar assessment')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>We’ll calculate your potential solar savings and recommend solar bundles and battery upgrades for your home.</p>
                <EnrollmentFormRow>
                    <EnrollmentFormCol full>
                        <Form.Group>
                            <RadioBox
                                title={`${t('What type of solar power system you would like to install?')} *`}
                                options={serviceOptions}
                                select={selectedProduct}
                                handleSelect={(val: any) => {
                                    setSelectedProduct(val);
                                    let res = `solar=${val.value}`;
                                    handleConfirm(res);
                                }}
                                recommend={0}
                            />
                            <FormError message={errorFields['product']} />
                        </Form.Group>
                    </EnrollmentFormCol>
                </EnrollmentFormRow>
                {/* <div className="text-center pt-4 pb-3">
                    <Button type="button" onClick={() => confirm()} data-test-id="cta">
                        <span>{t('Continue')}</span>
                    </Button>
                </div> */}
            </Modal.Body>
        </Modal>
    )
}