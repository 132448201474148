import { EnrollmentRadioGroup, EnrollmentRadioBox } from "./styles/Enrollment.styled";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Options {
    id?: string;
    label: string;
    value: string|number;
    desc?: string;
    img? : string;
}

interface Props {
    options: Array<Options>;
    select: Options | undefined;
    handleSelect: Function;
    title: string;
    error?: boolean;
    recommend?: number;
}

export const RadioBox: React.FC<Props> = ({ options, select, handleSelect, title, recommend }) => {
    return (
        <>
            <h6>{title}</h6>
            <EnrollmentRadioGroup>
                {
                    options.map((val, idx) => (
                        <EnrollmentRadioBox
                            key={idx}
                            onClick={() => {handleSelect(val)}}
                            data-test-id={val.id ? val.id : ''}
                            isRecommend={recommend === idx || false}
                        >
                            <div>
                                {
                                    select && select.value === val.value ?
                                    <FontAwesomeIcon icon="check-square" style={{color:'#5FB149'}} /> :
                                    <FontAwesomeIcon icon="square" style={{color:'#eee'}} />
                                }
                                <span className="ms-2">{val.label}</span>
                            </div>
                            {
                                val.desc &&
                                <p>{val.desc}</p>
                            }
                            {
                                val.img &&
                                <img src={`/images/${val.img}`} alt="" />
                            }
                        </EnrollmentRadioBox>
                    ))
                }
            </EnrollmentRadioGroup>
        </>
    )
}