import styled from 'styled-components';

export const ProductRow = styled.div`
    /* border-bottom: 1px solid #ddd; */
    /* padding: 24px 0; */
    gap: 40px;
`

export const ProductList = styled.div`
    & > div:last-child {
        ${ProductRow} {
            border-bottom: 0 none;
        }
    }

    @media (max-width: 768px) {   
        .nav-tabs {
            display: flex;
        }

        .nav-item {
            display: inline-flex;
            align-items: center;
            flex: 1;
            line-height: 1.1;
            font-size: 12px;
            padding-left: 8px;
            padding-right: 8px;
            justify-content: center;
            text-align: center;
        }
    }
`

export const ProductCol = styled.div`
    h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    p {
        font-size: 15px;
        line-height: 1.3;
    }

    b {
        font-weight: 500;
        margin-left: 24px;
    }
`

export const SavingCol = styled.div`

`

export const ProductGroupList = styled.div`
    margin: 8px 0;
`

export const ProductGroupInfoImg = styled.div`
    width: 100px;
    height: 100px;
    background: #fff;
    margin-right: 16px;
    flex-shrink: 0;
    border: 1px solid #eee;

    img {
        width: 100%;
        height: 100%;
    }
`

export const ProductGroupInfo = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

export const ProductGroupPrice = styled.div`
    text-align: right;
    width: 160px;

    i {
        display: block;
        font-style: normal;
        font-size: 14px;
    }
`

interface ProductGroupItemProps {
    sm?: boolean;
}

export const ProductGroupItem = styled.div<ProductGroupItemProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    background-color: #F9F9F9;
    border-radius: 4px;
    margin: 12px 0;

    h4 {
        font-size: 17px;
        font-weight: 600;
    }

    p {
        font-size: 15px;
        color: #666;
        margin-bottom: 0;
    }

    b {
        /* font-size: 17px;
        font-weight: 500; */
        flex-shrink: 0;
        text-align: right;
        width: 80px;
        /* flex: 1; */
    }

    ${ProductGroupInfoImg} {
        width: ${({ sm }) => sm ? '50px' : '100px'};
        height: ${({ sm }) => sm ? '50px' : '100px'};
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        ${ProductGroupInfo} {
            width: 100%;
        }

        b {
            width: 100%;
            margin-left: 0;
        }

        ${ProductGroupPrice} {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: 8px;
        }
    }
`

export const ProductGroupInfoMain = styled.div`
    flex: 1;
`

export const UsageInsights = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1200px) {
        flex-direction: column;
    }

    @media (max-width: 992px) {
        flex-direction: row;
    }
`

export const UsageInsightsItem = styled.dl`
    flex: 0 0 47%;
    margin: 2.5% 1.5%;
    padding: 12px 20px;
    margin-bottom: 0;
    background-color: #F9F9F9;
    border-radius: 4px;

    dd {
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 18px;
    }

    dt {
        font-size: 13px;
        opacity: .6;
        line-height: 1.1;
        font-weight: 400;
    }
`

export const SavingChartWrapper = styled.div`
    height: 120px;
    margin-top: 24px;
`

export const FakeLegend = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;

    &::before {
        content: ' ';
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background-color: #5FB149;
    }
`

export const ProductMain = styled.div`
    margin-top: -240px;
    position: relative;
    z-index: 1;
`

export const AddressInfo = styled.div`
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #eee;
`

export const PaybackRow = styled.div`
    display: inline-flex;
    align-items: center;
    background: #F8F0ED;
    color: #F06E2E;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 8px;
    flex-wrap: wrap;

    span {
        margin: 0 4px;
        font-weight: 600;
    }

    ::before {
        display: inline-block;
        flex-shrink: 0;
        content: ' ';
        width: 20px;
        height: 20px;
        mask: url('/images/icons/credit-card-arrow-right.svg') 0 0 no-repeat;
        mask-size: cover;
        background-color: #F06E2E;
        margin-right: 8px;
    }

    @media (max-width: 460px) {
        font-size: 13px;
    }
`

export const PaybackInfo = styled.div`
    display: flex;
    margin-top: 44px;
    margin-bottom: 24px;
    gap: 12px;

    dl {
        padding: 12px 16px;
        margin-bottom: 0;
        /* background-color: #F9F9F9; */
        border-radius: 4px;
        border-right: 1px solid #ddd;
    }

    dl:last-child {
        border-right: 0 none;
    }

    dd {
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 18px;
    }

    dt {
        font-size: 13px;
        opacity: .6;
        line-height: 1.1;
        font-weight: 400;
    }
`

export const TotalRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 24px;

    & > div {
        text-align: right;

        div {
            margin-bottom: 4px;
        }

        div:last-child {
            font-weight: 600;
            font-size: 24px;
            border-top: 1px solid #ddd;
            padding-top: 12px;
            margin-top: 10px;
        }
    }

    label {
        font-weight: 700;
        font-size: 18px;
    }

    i {
        display: inline-flex;
        align-items: center;
        color: #F06E2E;
        font-weight: 500;

        &::before {
            display: inline-block;
            content: ' ';
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            mask: url('/images/icons/tag.svg') 0 0 no-repeat;
            mask-size: cover;
            background-color: #F06E2E;
            margin-right: 4px;
        }
    }
`

export const ProductItemSpec = styled.div`
    line-height: 1.1;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #eee;
    font-size: 15px;
`

export const SavingTable = styled.div`
    background-color: #eee;
    border-radius: 4px;

    table {
        border-collapse: inherit;
    }

    td, th {
        padding: 12px 20px;
        background-color: #fff;
        line-height: 1.2;
    }

    td {
        text-align: center;
    }

    th {
        font-size: 18px;
        text-align: center;
    }

    .fs-col {
        text-align: left;
        font-weight: 500;
    }

    .total-row {
        font-size: 18px;
        font-weight: 700;
    }

    .highlight {
        font-size: 20px;
        color: #5FB149;
        /* background-color: #F2FAF0; */

        .tooltip-mark {
            border-color: #5FB149;
            color: #5FB149;
        }
    }

    .bt-row {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .bt-row.highlight {
        color: inherit;
        font-size: 24px;
        font-weight: 700;
        background-color: #fff;
    }

    @media (max-width: 460px) {
        td, th {
            padding: 12px;
        }

        th {
            font-size: 16px !important;
        }

        .fs-col {
            font-size: 12px;
        }

        .total-row {
            font-size: 14px;
        }
    }
`

export const QuoteSectionTitle =  styled.div`
    border-bottom: 1px solid #ddd;
    position: relative;
    margin: 20px 0 32px;

    h5 {
        font-size: 15px;
        color: #666;
        position: absolute;
        left: 50%;
        top: 50%;
        padding: 0 12px;
        background-color: #fff;
        transform: translate(-50%, -50%);
    }
`