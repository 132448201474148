export const serviceOptions = [
    { label: 'Solar with Battery', value: 0, img: 'icon-solar-battery.png' },
    { label: 'Battery Only', value: 1, img: 'icon-battery-only.png' },
    { label: 'Solar Only', value: 2, img: 'icon-solar-only.png' }
];

export const TAXCREDIT = 0.3; // 30%
export const SALESTAX = 0.0825; // 8.25%

export const allProducts = {
    inverter: [{
        name: 'Sol-Ark 8k: All-In-One Hybrid Inverter',
        price: 6200,
        spec: '8kw'
    },
    {
        name: 'Sol-Ark 12k: All-In-One Hybrid Inverter',
        price: 7100,
        spec: '12kw'
    },
    {
        name: 'Sol-Ark 15k: All-In-One Hybrid Inverter',
        price: 8250,
        spec: '15kw'
    }],
    solar: [{
        name: '455w',
        price: 480
    }, {
        name: '550w',
        price: 580
    }],
    battery: [{
        name: 'LifePo4 10kwh',
        price: 3100
    }]
}

export const laborCost = {
    0: { // Solar + Battery
        min: 3900, max: 8000
    },
    1: { // Battery
        min: 1800, max: 2500
    },
    2: { // Solar
        min: 3000, max: 5500
    }
}

export const partsCost = {
    0: { // Solar + Battery
        min: 1800, max: 3800
    },
    1: { // Battery
        min: 1000, max: 1800
    },
    2: { // Solar
        min: 5000, max: 7000
    }
}

export const propertyTypeOptions = [
    { label: 'House', value: 0 },
    { label: 'Townhouse', value: 1 },
    { label: 'Unit', value: 2 },
    { label: 'Apartment', value: 3 }
];

export const roofTypeOptions = [
    { label: 'Tin', value: 0 },
    { label: 'Concrete tile', value: 1 },
    { label: 'Terracotta tile', value: 2 },
    { label: 'Klip-lok', value: 3 },
    { label: 'Slate', value: 4 },
    { label: 'Asphalt', value: 5 },
    { label: 'Shingle', value: 6 },
    { label: 'Shingle', value: 7 },
    { label: 'Fibro', value: 8 },
    { label: 'Asbestos', value: 9 },
    { label: 'Timber', value: 10 },
    { label: 'EPDM', value: 11 },
    { label: 'Decromastic', value: 12 },
    { label: 'Flat concrete', value: 13 }
];

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function getDaysInMonth(month) {
    var year = new Date().getFullYear();
    var date = new Date(year, month - 1, 1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);
    return date.getDate();
}