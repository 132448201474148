import NumberFormat from "react-number-format"
import { LoadingRow } from "../loading-row"
import { CardBody, CardHeader, CardIcon } from "../styles/Common.styled"
import { PaybackRow, SavingCol, SavingTable } from "../styles/Product.styled"
import { useEffect, useState } from "react"
import { fetchMarketPrice } from "../../api/api"
import ReactTooltip from "react-tooltip"

export const SavingSection = ({ loading, totalUsage, total, totalRange }: {
    loading: boolean;
    totalUsage: number;
    total: number;
    totalRange: any;
}) => {
    const [ avgMarketPrice, setAvgMarketPrice ] = useState(0);

    useEffect(() => {
        getMarketPrice();
    }, [])

    const getMarketPrice = () => {
        fetchMarketPrice().then(res => {
            if (res && res.status === '1') {
                const { data } = res;
                const latestMonth = data[0].month;
                const latestResults = data.filter((val: any) => val.month === latestMonth && val.average_usage === '2000' && val.term === '12');
                if (latestResults.length > 0) {
                    let sum = latestResults.reduce((accu: number, curr: any) => accu + parseFloat(curr.average_rate), 0);
                    setAvgMarketPrice(sum / latestResults.length);
                }
            }
        })
    }

    const displayPaybackRow = () => {
        if (!avgMarketPrice || !totalUsage || totalRange.min === 0 || totalRange.max === 0) return null;

        const monthlySaving = (avgMarketPrice * totalUsage / 12) - (avgMarketPrice * 90);
        const paybackYear = {
            min: totalRange.min / (monthlySaving * 12),
            max: totalRange.max / (monthlySaving * 12)
        }

        return (
            <>
                <PaybackRow>Payback in <NumberFormat value={paybackYear.min} displayType={'text'} thousandSeparator={true} decimalScale={1} /> - <NumberFormat value={paybackYear.max} displayType={'text'} thousandSeparator={true} decimalScale={1} /> years</PaybackRow>
            </>
        )
    }

    return (
        <>
            <CardHeader>
                <CardIcon type="saving" />
                <div>
                    <h3>Total Saving</h3>
                    {/* <p>The recommended setup is based on your consumption results</p> */}
                </div>
            </CardHeader>
            <CardBody>
                {
                    (!loading && totalUsage > 0) &&
                    <SavingCol>
                        <div className="pt-4 pb-4">
                            {
                                avgMarketPrice > 0 ?
                                <>
                                    <SavingTable>
                                        <table width="100%">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Electric Plans</th>
                                                    <th className="highlight">Solar Solution</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="fs-col">Avg. Monthly Usage</td>
                                                    <td colSpan={2}><NumberFormat value={totalUsage / 12} displayType={'text'} thousandSeparator={true} decimalScale={0} suffix="kWh" /></td>
                                                </tr>
                                                <tr>
                                                    <td className="fs-col">Avg. Market Rate</td>
                                                    <td><NumberFormat value={avgMarketPrice * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix="¢" /></td>
                                                    <td>/</td>
                                                </tr>
                                                <tr>
                                                    <td className="fs-col">Estimated Monthly Electric Bill</td>
                                                    <td className="total-row"><NumberFormat value={avgMarketPrice * (totalUsage / 12)} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix="$" /></td>
                                                    <td className="total-row highlight">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <NumberFormat value={avgMarketPrice * 90} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix="$" />
                                                            <i data-tip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." className="tooltip-mark ms-2">?</i>
                                                            <ReactTooltip className="tooltip" effect="solid" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="fs-col bt-row"><b>Monthly Saving</b></td>
                                                    <td colSpan={2} className="bt-row highlight">
                                                        <div className="mb-2">
                                                            <NumberFormat value={((avgMarketPrice * (totalUsage / 12)) - (avgMarketPrice * 90)) / (avgMarketPrice * (totalUsage / 12)) * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix="%" />
                                                            {/* <NumberFormat value={(avgMarketPrice * (totalUsage / 12)) - (avgMarketPrice * 90)} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix="$" /> */}
                                                        </div>
                                                        {/* { displayPaybackRow() } */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </SavingTable>
                                </> :
                                <div className="pt-3 pb-3"><LoadingRow /></div>
                            }
                        </div>
                    </SavingCol>
                }
            </CardBody>
        </>
    )
}