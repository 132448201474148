import { PureComponent } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'
import { ChartTooltips } from '../styles/Plan.styled'
import NumberFormat from 'react-number-format'
import { months } from '../../common'

const tooltipStyles = {
    cursor: {
        fill: 'transparent'
    },
}

export const UsageChart = ({ data }: {
    data: any;
}) => {
    let chartData = [];
    for (let i in data) {
        chartData.push({ name: months[parseInt(i) - 1], value: data[i] })
    }

    return (
        <>
            <ResponsiveContainer debounce={300} width="100%" height={100}>
                <BarChart
                    data={chartData}
                    margin={{
                        top: 12, right: 24, left: -24, bottom: 0,
                    }}
                >
                    <XAxis dataKey="name" tick={{fontSize:11}} stroke="#bbb" interval={1} />
                    <YAxis tick={{fontSize:11, fill: '#bbb'}} stroke="#fff" />
                    <CartesianGrid vertical={false} strokeDasharray="2" />
                    <Tooltip
                        { ... tooltipStyles }
                        wrapperStyle={{ zIndex: 1 }}
                        content={ <CustomTooltip /> }
                    />
                    <Bar
                        dataKey="value"
                        isAnimationActive={true}
                        maxBarSize={10}
                        fill="#ccc"
                        // shape={BarWithTopBorder()}
                    />
                </BarChart>
            </ResponsiveContainer>
        </>
    )
}

class CustomTooltip extends PureComponent {
    render() {
        const { active, payload }: Readonly<any> = this.props;
        
        if (!active || !payload || !payload[0]) return null;
        const { name, value } = payload[0].payload;
        
        return (
            <ChartTooltips>
                <h6>{name}</h6>
                <p><NumberFormat value={value} displayType={'text'} thousandSeparator={true} suffix={' kWh'} /></p>
            </ChartTooltips>
        )
    }
}

// const BarWithTopBorder = () => {
// 	return (props: any) => {
// 		const { x, y, width, height } = props;

// 		return (
// 			<g className="bar-cell">
// 				<rect x={x} y={y} width={width} height={height} stroke="none" className="bar" rx="2" ry="2" />
// 				<rect x={x} y={y} width={width} height={3} stroke="none" className="bar-top" rx="2" ry="2" />
// 			</g>
// 		)
// 	}
// }