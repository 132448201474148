import NumberFormat from 'react-number-format'
import { ProductCol, ProductGroupItem, ProductGroupInfoImg, ProductGroupList, ProductGroupInfo, ProductGroupPrice, ProductRow, ProductGroupInfoMain, TotalRow, ProductItemSpec } from '../styles/Product.styled'
import { TextButton } from '../styles/Button.styled'
import { SALESTAX, TAXCREDIT, allProducts, laborCost, partsCost } from '../../common'
import Select from 'react-select';

const selectStyles = {
    control: (style: object) => ({
        ...style,
        borderColor: '#ced4da',
        borderRadius: 4
    }),
    singleValue: (style: object) => ({
        ...style,
        color: '#495057',
        fontSize: 13
    }),
    valueContainer: (style: object) => ({
        ...style,
        padding: '4px 6px'
    })
}

export const ProductItem = ({
    data, type, handleShowOptions, handleChangeInverter,
    selectedInverter, selectedSolar, selectedBattery,
    isInstaller, totalRange, peakDailyUsage, handleChangeSolar
}: {
    data: any;
    type: number;
    totalRange: any;
    handleShowOptions: Function;
    handleChangeInverter: Function;
    handleChangeSolar: Function;
    selectedSolar: any;
    selectedBattery: any;
    selectedInverter: any;
    isInstaller?: boolean;
    peakDailyUsage: any;
}) => {
    if (!data) return null;
    
    const totalSolarPrice = data.panel > 0 ? selectedSolar.price * data.panel : 0;
    const totalBatteryPrice = data.battery > 0 ? selectedBattery.price * data.battery : 0;
    const totalInverterPrice = selectedInverter.price;

    return (
        <ProductRow>
            <ProductCol>
                {
                    !isInstaller &&
                    <h3>
                        {data.label}
                        <TextButton onClick={() => handleShowOptions()}>See Other Options</TextButton>
                    </h3>
                }
                <ProductGroupList>
                    <ProductGroupItem>
                        <ProductGroupInfo>
                            <ProductGroupInfoImg>
                                <img src="/images/products/inverter.jpg" alt="" />
                            </ProductGroupInfoImg>
                            <ProductGroupInfoMain>
                                <h4>Solar Inverter</h4>
                                <Select
                                    instanceId="inverterSelect"
                                    value={selectedInverter}
                                    onChange={(obj: any) => {
                                        handleChangeInverter(obj);
                                    }}
                                    options={allProducts.inverter}
                                    getOptionLabel={option => `${option.name}`}
                                    getOptionValue={option => `${option.name}`}
                                    isSearchable={true}
                                    maxMenuHeight={200}
                                    styles={selectStyles}
                                />
                                <ProductItemSpec>{selectedInverter.spec}</ProductItemSpec>
                            </ProductGroupInfoMain>
                        </ProductGroupInfo>
                        <b>x 1</b>
                        <ProductGroupPrice>
                            <i className="mb-1">Total price</i>
                            <NumberFormat value={totalInverterPrice} displayType={'text'} thousandSeparator={true} prefix='$' />
                        </ProductGroupPrice>
                    </ProductGroupItem>
                    {
                        data.panel > 0 &&
                        <ProductGroupItem>
                            <ProductGroupInfo>
                                <ProductGroupInfoImg>
                                    <img src="/images/products/pannel.jpg" alt="" />
                                </ProductGroupInfoImg>
                                <ProductGroupInfoMain>
                                    <h4>Solar</h4>
                                    <Select
                                        instanceId="solarSelect"
                                        value={selectedSolar}
                                        onChange={(obj: any) => {
                                            handleChangeSolar(obj);
                                        }}
                                        options={allProducts.solar}
                                        getOptionLabel={option => `${option.name}`}
                                        getOptionValue={option => `${option.name}`}
                                        isSearchable={true}
                                        maxMenuHeight={200}
                                        styles={selectStyles}
                                    />
                                    <ProductItemSpec>{`${data.panel} solar panel can generate ${Math.round(data.panel * parseInt(selectedSolar.name) / 10) / 100} kWh of electricity per hour`}</ProductItemSpec>
                                </ProductGroupInfoMain>
                            </ProductGroupInfo>
                            <b>x {data.panel}</b>
                            <ProductGroupPrice>
                                <i className="mb-1">Total price</i>
                                <NumberFormat value={totalSolarPrice} displayType={'text'} thousandSeparator={true} prefix='$' />
                            </ProductGroupPrice>
                        </ProductGroupItem>
                    }
                    {
                        data.battery > 0 &&
                        <ProductGroupItem>
                            <ProductGroupInfo>
                                <ProductGroupInfoImg>
                                    <img src="/images/products/battery.jpg" alt="" />
                                </ProductGroupInfoImg>
                                <ProductGroupInfoMain>
                                    <h4>Energy Storage</h4>
                                    <p>LifePo4 10kwh</p>
                                    {
                                        peakDailyUsage &&
                                        (
                                            data.panel > 0 ? 
                                            <ProductItemSpec>Based on <NumberFormat value={peakDailyUsage.value * .3} displayType={'text'} thousandSeparator={true} decimalScale={0} suffix='kWh' /> nighttime comsumption</ProductItemSpec> :
                                            <ProductItemSpec>Based on <NumberFormat value={peakDailyUsage.value * .7} displayType={'text'} thousandSeparator={true} decimalScale={0} suffix='kWh' /> daytime comsumption</ProductItemSpec>
                                        )
                                    }
                                </ProductGroupInfoMain>
                            </ProductGroupInfo>
                            <b>x {data.battery}</b>
                            <ProductGroupPrice>
                                <i className="mb-1">Total price</i>
                                <NumberFormat value={totalBatteryPrice} displayType={'text'} thousandSeparator={true} prefix='$' />
                            </ProductGroupPrice>
                        </ProductGroupItem>
                    }
                    <ProductGroupItem sm>
                        <ProductGroupInfo>
                            <ProductGroupInfoMain>
                                <h4>Parts</h4>
                                <p>...</p>
                            </ProductGroupInfoMain>
                        </ProductGroupInfo>
                        <ProductGroupPrice>
                            <NumberFormat value={(partsCost as any)[type].min} displayType={'text'} thousandSeparator={true} prefix='$' />
                            {/* {`$${(partsCost as any)[type].min} - $${(partsCost as any)[type].max}`} */}
                            {/* <NumberFormat value={100} displayType={'text'} thousandSeparator={true} prefix='$' /> */}
                        </ProductGroupPrice>
                    </ProductGroupItem>
                    <ProductGroupItem sm>
                        <ProductGroupInfo>
                            <ProductGroupInfoMain>
                                <h4>Labor</h4>
                                <p>...</p>
                            </ProductGroupInfoMain>
                        </ProductGroupInfo>
                        <ProductGroupPrice>
                            <NumberFormat value={(laborCost as any)[type].min} displayType={'text'} thousandSeparator={true} prefix='$' />
                            {/* {`$${(laborCost as any)[type].min} - $${(laborCost as any)[type].max}`} */}
                            {/* <NumberFormat value={100} displayType={'text'} thousandSeparator={true} prefix='$' /> */}
                        </ProductGroupPrice>
                    </ProductGroupItem>
                </ProductGroupList>
                <TotalRow>
                    <label>Total:</label>
                    <div>
                        <div>
                            <NumberFormat value={totalRange.excludeTaxMin} displayType={'text'} thousandSeparator={true} prefix='$' />
                            {/* - <NumberFormat value={totalRange.excludeTaxMax} displayType={'text'} thousandSeparator={true} prefix='$' /> */}
                        </div>
                        <div className="d-flex gap-2 align-items-end" style={{lineHeight:1.1}}>
                            Include {`${SALESTAX * 100}%`} Sales Tax<br />(Exclude Labor Cost): <NumberFormat value={totalRange.min} displayType={'text'} thousandSeparator={true} prefix='$' />
                            {/* - <NumberFormat value={totalRange.max} displayType={'text'} thousandSeparator={true} prefix='$' /> */}
                        </div>
                        <div className="mt-3">
                            <i>
                                Tax Credit: 30% = <NumberFormat value={totalRange.min * TAXCREDIT} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix='$' className="ms-1" />
                                {/* - <NumberFormat value={totalRange.max * TAXCREDIT} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix='$' className="ms-1" /> */}
                            </i>
                        </div>
                        <div>
                            <NumberFormat value={totalRange.min - totalRange.min * TAXCREDIT} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix='$' />
                             {/* - <NumberFormat value={totalRange.max - totalRange.max * TAXCREDIT} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix='$' /> */}
                        </div>
                    </div>
                </TotalRow>
                <p className="mt-5">The above is an estimated price. The actual price will be determined when the workers arrive on-site.</p>
            </ProductCol>
        </ProductRow>
    )
}