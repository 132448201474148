import styled from 'styled-components';

export const StyledFooter = styled.div`
    background-color: #F3F4F0;
    color: #666;
    padding-top: 44px;
    padding-bottom: 24px;
`

export const Copyright = styled.div`
    font-size: 13px;
    opacity: .5;
    text-align: center;
    font-weight: 300;
    /* margin-top: 44px; */

    a {
        color: #fff;
    }
`

export const FooterNavRow = styled.div`
    display: flex;
`

export const FooterNavCol = styled.div`
    flex: 1;
    text-align: center;

    & > div{
        padding: 4px 0;
    }

    h6{
        margin-bottom: 16px;
    }

    a{
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        opacity: .6;
    }
`