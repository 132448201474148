import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import type { GetUsageModalProps } from '../types'
import { GetUsageComponent } from '../components/get_usage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const GetUsageModal: React.FC<GetUsageModalProps> = ({
    show,
    handleClose,
    handleSetConsentId,
    handleFetchActualUsage,
    defaultView,
    zipcode
}) => {
    if (!show) return null
    return (
        <div className={`${show ? 'active' : ''}`}>
            <div className="side-modal-body">
                <div className="position-absolute" style={{ top: 20, right: 20 }}>
                    <BootstrapButton
                        className="close-btn"
                        variant="link"
                        onClick={() => handleClose()}
                        style={{ fontSize: 32, color: '#000' }}>
                        <FontAwesomeIcon icon="times" />
                    </BootstrapButton>
                </div>
                <GetUsageComponent
                    fetchActualUsage={() => {
                        handleFetchActualUsage()
                        handleClose()
                    }}
                    handleSetConsentId={handleSetConsentId}
                    defaultView={defaultView}
                    inModal={true}
                    zipcode={zipcode}
                    forcastUsage={() => {
                        handleClose()
                    }}
                    handleNext={() => {
                        handleFetchActualUsage()
                        handleClose()
                    }}
                />
            </div>
            <div className="mask" onClick={() => handleClose()} />
        </div>
    )
}
