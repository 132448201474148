import styled from 'styled-components';

interface RowProps {
    reverse?: boolean;
}

export const SectionRow = styled.div<RowProps>`
    display: flex;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    align-items: center;
    padding: 64px 0;
    margin: 24px 0;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const SectionContent = styled.div`
    padding: 0 44px;

    h2 {
        margin-bottom: 24px;
    }

    p {
        color: #666;
    }

    @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
`

export const SectionImg = styled.div`
    flex-shrink: 0;
    max-width: 45%;
    
    img {
        max-width: 100%;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`

export const HomeSection = styled.div`
    background-color: #fff;
    text-align: center;
    padding-top: 64px;
    padding-bottom: 32px;
`

export const HomepageTopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 160px 0 240px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`

export const HomepageTopSectionMain = styled.div`
    text-align: center;
    max-width: 760px;
    margin: 0 auto;

    h1 {
        font-size: 58px;
        font-weight: 600;
        line-height: 1.1;
        margin-bottom: 24px;
        color: #fff;
    }

    h1 span {
        color: #F06E2E;
    }

    p {
        font-weight: 300;
        color: #fff;
        margin-bottom: 32px;
    }

    @media (max-width: 991px) {
        h1 {
            font-size: 45px;
        }
    }
`

export const TestimonialItem = styled.div`
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    gap: 24px;

    & > div {
        width: 100px;
        height: 100px;
        background-color: #ddd;
        flex-shrink: 0;
    }

    p {
        margin-bottom: 0;
    }
`

export const QuoteSection = styled.div`
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const QuoteInto = styled.div`
    flex: 1;

    h2 {
        margin-bottom: 24px;
        max-width: 540px;
    }

    p {
        max-width: 540px;
    }

    @media (max-width: 768px) {
        margin-bottom: 24px;
    }
`

export const QuoteForm = styled.div`
    width: 50%;
    font-size: 14px;
    
    max-width: 640px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #eee;

    h2 {
        color: #333;
    }

    p {
        color: #333;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`

export const FeatureSection = styled.div`
    display: flex;
    gap: 30px;
    position: relative;
    /* z-index: 1; */
    margin-top: -180px;
    align-items: flex-end;

    @media (max-width: 991px) {
        flex-direction: column;
    }
`

interface FeatureBoxProps {
    highlight?: boolean;
}

export const FeatureBox = styled.div<FeatureBoxProps>`
    background-color: ${({ highlight }) => highlight ? '#253723' : '#fff'};
    padding: ${({ highlight }) => highlight ? '72px 20px 20px' : '24px 20px 20px'};
    flex: ${({ highlight }) => highlight ? 1.3 : 1};
    border-radius: 8px;
    color: ${({ highlight }) => highlight ? '#fff' : 'inherit'};
    border: 1px solid ${({ highlight }) => highlight ? 'transparent' : '#eee'};
    /* box-shadow: 0 3px 6px rgba(0, 0, 0, .04); */
    text-align: center;
    min-height: ${({ highlight }) => highlight ? '340px' : '280px'};

    h5 {
        font-size: ${({ highlight }) => highlight ? '24px' : '18px'};
        margin-bottom: 20px;
    }

    p {
        font-size: ${({ highlight }) => highlight ? '15px' : '14px'};
        opacity: ${({ highlight }) => highlight ? 1 : .6};
        margin-bottom: 0;
    }

    @media (max-width: 991px) {
        min-height: auto;
        padding: 44px 20px;
    }
`

interface FeatureIconProps {
    type: string;
}

const featureIconSrc = ({ type }: FeatureIconProps) => {
    if (type === 'customized') return 'location-crosshair.svg';
    if (type === 'team') return 'team-member.svg';
    if (type === 'technology') return 'cube-dash.svg';
    if (type === 'satisfaction') return 'badge.svg';
    return '';
}

export const FeatureIcon = styled.div<FeatureIconProps>`
    width: 36px;
    height: 36px;
    margin: 0 auto 24px;
    mask: url('/images/icons/${featureIconSrc}') 0 0 no-repeat;
    mask-size: cover;
    background-color: #F06E2E;
`

interface SectionWrapperProps {
    isDark?: boolean;
    withBg?: boolean;
}

export const SectionWrapper = styled.div<SectionWrapperProps>`
    background: ${({ isDark }) => isDark ? 'linear-gradient(0deg,rgba(5,23,3,0.85) 0%,rgba(5,23,3,1) 100%)' : '#fff'};
    color: ${({ isDark }) => isDark ? '#fff' : 'inherit'};
    padding: 80px 0;
    position: ${({ withBg }) => withBg ? 'relative' : 'static'};
`

export const SectionInner = styled.div`
    
`

export const SectionHeader = styled.div`
    h2 {
        text-align: center;
        font-size: 36px;
    }

    p {
        text-align: center;
        max-width: 640px;
        margin: 20px auto 0;
    }
`

export const SectionBody = styled.div`
    margin-top: 64px;
`

export const SectionFooter = styled.div`
    margin-top: 44px;
    text-align: center;
`

export const ServicesList = styled.div`
    display: flex;
    gap: 30px;

    @media (max-width: 991px) {
        flex-direction: column;
    }
`

export const ServicesItem = styled.div`
    background-color: #fff;
    border-radius: 8px;
    color: #333;
    padding: 24px 32px;
    text-align: center;
    flex: 1;

    h5 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        opacity: .6;
        margin-bottom: 0;
    }
`

interface ServiceIconProps {
    type: string;
}

const serviceIconSrc = ({ type }: ServiceIconProps) => {
    if (type === 'solar') return 'bulb-solar-sun.svg';
    if (type === 'battery') return 'battery.svg';
    if (type === 'hybird') return 'sq-panel-battery.svg';
    if (type === 'maintenance') return 'wrench-panel.svg';
    return '';
}

export const ServiceIcon = styled.div<ServiceIconProps>`
    width: 96px;
    height: 96px;
    margin: 0 auto 24px;
    mask: url('/images/icons/${serviceIconSrc}') 0 0 no-repeat;
    mask-size: cover;
    background-color: #F06E2E;
`

export const SectionBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .15;
    overflow: hidden;
    z-index: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`