import { Spinner } from 'react-bootstrap';

export const FormLoading = (
    {
        show,
        content
    }: {
        show: boolean,
        content?: string
    }
) => {
    if (!show) return null;
    return (
        <div
            className="position-absolute"
            style={{
                top:0,
                right:0,
                bottom:0,
                left:0
            }}
        >
            <div
                className="position-absolute text-center"
                style={{
                    top:'50%',
                    left:'50%',
                    transform:'translate(-50%, -50%)',
                    background:'rgba(0, 0, 0, .7)',
                    borderRadius:4,
                    padding:'32px 32px',
                    maxWidth:400,
                    boxShadow:'0 4px 12px rgba(0, 0, 0, .2)'
                }}
            >
                <Spinner animation="border" variant="light" />
                {
                    content &&
                    <div
                        className="mt-3"
                        style={{
                            color:'#fff',
                            cursor:'default'
                        }}
                    >{content}</div>
                }
            </div>
        </div>
    )
}