import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import {
    StyledFooter,
    Copyright,
    FooterNavRow,
    FooterNavCol
} from './styles/Footer.styled'

interface Item {
    title: string
    links: NavLink[]
}

interface NavLink {
    name: string
    url: string
}

const Footer = () => {
    const { t } = useTranslation()

    const nav: Item[] = [
        {
            title: t('Company'),
            links: [
                {
                    name: t('About'),
                    url: '#',
                },
                {
                    name: t('Shopping Tips'),
                    url: '#',
                },
                { name: t('Terms of Service'), url: `#` },
                { name: t('Privacy Policy'), url: `#` },
            ],
        },
        {
            title: t('Contact'),
            links: [
                {
                    name: t('Customer Support'),
                    url: '#',
                },
                {
                    name: t('General Inquires'),
                    url: '#',
                },
            ],
        },
        {
            title: t('Social Media'),
            links: [
                { name: 'Facebook', url: '#' },
                { name: 'Instagram', url: '#' },
                { name: 'Twitter', url: '#' },
                { name: 'Youtube', url: '#', },
            ],
        },
    ]

    return (
        <StyledFooter>
            <Container>
                {/* <FooterNavRow>
                    {nav.map((val, idx) => (
                        <FooterNavCol key={idx}>
                            <h6>{val.title}</h6>
                            {val.links.map((item, itemIdx) => {
                                return (
                                    <div key={itemIdx}>
                                        <a href={item.url} rel="noreferrer">
                                            {item.name}
                                        </a>
                                    </div>
                                )
                            })}
                        </FooterNavCol>
                    ))}
                </FooterNavRow> */}
                <Copyright>
                    © Copyright {moment().format('YYYY')}
                </Copyright>
            </Container>
        </StyledFooter>
    )
}

export default Footer
