import styled from 'styled-components';

interface ButtonProps {
    variant?: string;
    md?: boolean;
    disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
    background: ${props => props.disabled ? (props.variant === 'secondary' ? '#fff' : '#B7CCB1') : (props.variant === 'secondary' ? '#fff' : '#5FB149')};
    color: ${props => props.variant === 'secondary' ? '#777' : '#fff'};
    border: ${props => props.variant === 'secondary' ? '1px solid #eee' : '0 none'};
    font-weight: 600;
    font-size: ${props => props.md ? '14px' : '16px'};
    padding: ${props => props.md ? '8px 16px' : '12px 32px'};
    border-radius: 4px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    transition: all .3s;
    :hover{
        background: ${props => props.disabled ? (props.variant === 'secondary' ? '#fff' : '#B7CCB1') : (props.variant === 'secondary' ? '#fff' : '#448F2F')};
    }
`

export const TextButton = styled.button`
    border: 0 none;
    background-color: transparent;
    font-size: 16px;
    color: #5FB149;
    font-weight: 600;
    display: inline-flex;
    align-items: center;

    ::after {
        content: ' ';
        display: inline-block;
        width: 20px;
        height: 20px;
        mask: url('/images/icons/chevron-right.svg') 0 0 no-repeat;
        mask-size: cover;
        background-color: #5FB149;
    }
`