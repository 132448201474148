import { getDaysInMonth } from "./common";

export class SolarPlan {
    constructor (usage) {
        this.usage = usage;
        this.chargingTime = 5;        // hrs
        this.daylightPercentage = 80; // %
        this.nightPercentage = 40;    // %
        this.solarPanelkWh = 400;     // w
        this.batteryCapacity = 10;    // kw
        this.avgDailyUsage = this.calDailyUsage();
    }

    calDailyUsage = () => {
        const maxKey = this.usage.reduce(function (maxIndex, currentValue, currentIndex, array) {
            if (currentValue > array[maxIndex]) {
                return currentIndex;
            } else {
                return maxIndex;
            }
        }, 0);
        const peakUsage = parseInt(this.usage[maxKey]);
        const peakUsageDaily = peakUsage / getDaysInMonth(maxKey + 1);

        const res = {
            daylight: peakUsageDaily * (this.daylightPercentage / 100),
            night: peakUsageDaily * (this.nightPercentage / 100),
            total: peakUsageDaily
        };
        
        return res;
    }

    getSolarOnlyPlan () {
        return new Promise ((resolve) =>{
            // console.log('---- Solar Panel Only ----');
            const panel = Math.ceil(this.avgDailyUsage.total / this.chargingTime * 1000 / this.solarPanelkWh);
            const res = {
                type: 2,
                label: 'Solar Only',
                panel: panel,
                battery: 0
            };

            resolve(res);
        })
    }

    getSolarBatteryPlan () {
        return new Promise ((resolve) =>{
            // console.log('---- Solar with Battery ----');
            const panel = Math.ceil(this.avgDailyUsage.total / this.chargingTime * 1000 / this.solarPanelkWh);
            let battery = Math.ceil(this.avgDailyUsage.night / this.batteryCapacity);
            battery = Math.ceil(battery + battery * .1);
            const res = {
                type: 0,
                label: 'Solar with Battery',
                panel: panel,
                battery: battery
            };
            
            resolve(res);
        })
    }

    getBatteryOnlyPlan () {
        return new Promise ((resolve) =>{
            // console.log('---- Battery Only ----');
            let battery = Math.ceil(this.avgDailyUsage.daylight / this.batteryCapacity);
            battery =  Math.ceil(battery + battery * .1);
            const res = {
                type: 1,
                label: 'Battery Only',
                panel: 0,
                battery: battery
            };
            
            resolve(res);
        })
    }
}