import { useLocation } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardIcon } from '../styles/Common.styled'
import { UsageChart } from '../product/usageChart'
import { AddressInfo, UsageInsights, UsageInsightsItem } from '../styles/Product.styled'
import { LoadingRow } from '../loading-row'
import qs from 'qs'
import { MonthlyUsageProps, AddressBlockProps, PremiseInfo
} from '../../types'
import NumberFormat from 'react-number-format'
import { getDaysInMonth, months } from '../../common'
import { Button } from '../styles/Button.styled'
import { useEffect, useState } from 'react'
import { fetchPremiseDetail } from '../../api/api'
import { css } from '@emotion/css'

export const ProductAside = ({
    loading, usage, totalUsage, isInstaller, handleShowGetUsageModal, isActualUsage, peakDailyUsage
}: {
    loading: boolean;
    usage: MonthlyUsageProps | undefined
    totalUsage: number;
    isInstaller?: boolean;
    isActualUsage?: boolean;
    handleShowGetUsageModal: Function;
    peakDailyUsage: any;
}) => {
    const location = useLocation();
    const { addr, zipcode, city, state } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [ premiseDetail, setPremiseDetail ] = useState<PremiseInfo | undefined>(undefined);

    useEffect(() => {
        getPremiseDetail();
    }, [])

    const getPremiseDetail = () => {
        fetchPremiseDetail(zipcode, addr).then((res) => {
            if (res.status === 1) {
                setPremiseDetail(res.premise);
            }
        });
    }

    const consumptionSummary = () => {
        if (!peakDailyUsage) return null;

        // const usageArr = Object.values(usage);
        // const maxKey = usageArr.reduce(function (maxIndex, currentValue, currentIndex, array) {
        //     if (currentValue > array[maxIndex]) {
        //         return currentIndex;
        //     } else {
        //         return maxIndex;
        //     }
        // }, 0);
        // const peakUsage: number = parseInt(usageArr[maxKey]);
        // const peakUsageDaily = peakUsage / getDaysInMonth(maxKey + 1);

        return (
            <UsageInsights>
                <UsageInsightsItem>
                    <dd><NumberFormat value={totalUsage / 12} displayType={'text'} thousandSeparator={true} decimalScale={0} suffix=' kWh' /></dd>
                    <dt>Avg. Monthly Power Consumption</dt>
                </UsageInsightsItem>
                <UsageInsightsItem>
                    <dd>{peakDailyUsage.month}</dd>
                    <dt>Peak Power Consumption</dt>
                </UsageInsightsItem>
                <UsageInsightsItem>
                    <dd><NumberFormat value={peakDailyUsage.value * .7} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix=' kWh' /></dd>
                    <dt>Daytime Consumption</dt>
                </UsageInsightsItem>
                <UsageInsightsItem>
                    <dd><NumberFormat value={peakDailyUsage.value * .3} decimalScale={0} displayType={'text'} thousandSeparator={true} suffix=' kWh' /></dd>
                    <dt>Nightime Consumption</dt>
                </UsageInsightsItem>
            </UsageInsights>
        )
    }

    return (
        <Card>
            {
                !isInstaller &&
                <CardHeader>
                    <CardIcon type="usage" />
                    <div>
                        <h3>Consumption Results</h3>
                        <p>We automatically calculate your consumption according to your needs</p>
                    </div>
                </CardHeader>
            }
            <CardBody>
                {
                    (loading || !usage) ?
                    <div className="pt-5 pb-5"><LoadingRow /></div> :
                    <>
                        <UsageChart data={usage} />
                        {
                            // isInstaller &&
                            // (isActualUsage ? 
                            // <p className="text-center mt-2 mb-3">Usage from SMT</p> :
                            // <div className="text-center mt-2 mb-3">
                            //     <Button onClick={() => handleShowGetUsageModal()}>Get Usage</Button>
                            // </div>)
                        }
                        { consumptionSummary() }
                    </>
                }
                <AddressBlock premiseDetail={premiseDetail} />
                {
                    // !isInstaller &&
                    // <AddressInfo>
                    //     {/* <AddressBlock premiseDetail={premiseDetail} /> */}
                    //     {
                    //         (addr && city && state && zipcode) &&
                    //         <iframe
                    //             width="100%"
                    //             height="100%"
                    //             title="map"
                    //             style={{border:0}}
                    //             loading="lazy"
                    //             allowFullScreen
                    //             referrerPolicy="no-referrer-when-downgrade"
                    //             src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0&q=${encodeURIComponent(`${addr}, ${city}, ${state} ${zipcode}`)}&zoom=10`}>
                    //         </iframe>
                    //     }
                    // </AddressInfo>
                }
            </CardBody>
        </Card>
    )
}

const AddressBlock: React.FC<AddressBlockProps> = ({ premiseDetail }) => {
    const detail = premiseDetail ? premiseDetail.detail : undefined
    return (
        <div
            className={css`
                border-top: 1px solid #eee;
                padding-top: 12px;
                margin-top: 20px;
            `}>
            <div className="d-flex align-items-center justify-content-center">
                {detail && (
                    <div
                        className={css`
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #666;
                            margin-top: 2px;
                            gap: 20px;
                            span {
                                display: flex;
                                align-items: center;
                                font-weight: 600;
                            }
                        `}>
                        {detail.lot_size && (
                            <span>
                                <NumberFormat
                                    value={detail.lot_size}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    suffix=" SqFt"
                                />
                            </span>
                        )}
                        {detail.bedrooms && (
                            <span>
                                <img className="me-1" src="/images/icon-bedroom.png" width="24" alt="" />
                                {detail.bedrooms}
                            </span>
                        )}
                        {detail.bathrooms && (
                            <span>
                                <img className="me-1" src="/images/icon-bathroom.png" width="24" alt="" />
                                {detail.bathrooms}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}