import { useState } from 'react';
// import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
import { css } from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledHeader } from './styles/Header.styled';
import { Button } from './styles/Button.styled';

const Header = ({ isFloat }: {
    isFloat?: boolean
}) => {
    // const { t } = useTranslation();
    const navigate = useNavigate();
    const [ showNav, setShowNav ] = useState(false);

    const nav = [
        { label: 'Blog', url: "https://www.unblocksolar.com/blog/" }
        // { label: 'Product', url: "#" },
        // { label: 'Saving Calculator', url: "#" },
        // { label: 'Installation Services', url: "#" },
        // { label: 'About Us', url: "#" }
    ]
    
    return (
        <StyledHeader isFloat={isFloat !== undefined ? isFloat : true}>
            <div className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;
                a {
                    text-decoration: none;
                }
                span {
                    margin-left: 4px;
                    font-size: 12px;
                    color: #999;
                }
            `}>
                <a href="/" className={css`
                    font-size: 20px;
                    font-weight: 700;
                    color: #000;
                    &:hover {
                        color: #000;
                    }
                `}>Unblock Solar</a>
                <div className="align-items-center justify-content-center d-flex gap-3">
                    {
                        nav.map((val, idx) => (
                            <a href={val.url} key={idx} className="nav">{val.label}</a>
                        ))
                    }
                    <Button onClick={() => navigate('/quote')} className="d-none d-md-inline d-lg-inline d-xl-inline">Get Free Quote</Button>
                </div>
                {
                    // <div className="d-block d-md-none">
                    //     <button className={css`
                    //         border: 0 none;
                    //         background: transparent;
                    //         cursor: pointer;
                    //     `} onClick={() => {setShowNav(!showNav);}}>
                    //         <FontAwesomeIcon icon="bars" style={{fontSize:20,color:'#666'}} />
                    //     </button>
                    // </div>
                }
            </div>
            {
                // showNav &&
                // <div className={css`
                //     position: fixed;
                //     top: 0;
                //     right: 0;
                //     bottom: 0;
                //     left: 0;
                //     background: #fff;
                //     z-index: 999;
                // `}>
                //     <div className={css`
                //         display: flex;
                //         flex-direction: column;
                //         align-items: center;
                //         justify-content: center;
                //         height: 100%;
                //     `}>
                //         {
                //             nav.map((val, idx) => (
                //                 <a href={val.url} key={idx} className="nav" style={{marginTop:20,marginBottom:20}}>{val.label}</a>
                //             ))
                //         }
                //         <Button onClick={() => navigate('/quote')}>Get Free Quote</Button>
                //     </div>
                //     <button className={css`
                //         display: inline-block;
                //         position: absolute;
                //         top: 16px;
                //         right: 10px;
                //         border: 0 none;
                //         background: transparent;
                //         cursor: pointer;
                //         z-index: 100;
                //     `} onClick={() => setShowNav(false)}>
                //         <FontAwesomeIcon icon="times-circle" style={{color:'#aaa',fontSize:36}} />
                //     </button>
                // </div>
            }
        </StyledHeader>
    )
}

export default Header