import styled from 'styled-components';

interface StyledHeaderProps {
    isFloat: boolean
}

export const StyledHeader = styled.div<StyledHeaderProps>`
    position: ${({ isFloat }) => isFloat ? 'absolute' : 'relative'};
    top: 20px;
    width: 90%;
    left: 50%;
    padding: 0 44px;
    background: #fff;
    border-radius: 100px;
    transform: translateX(-50%);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
`