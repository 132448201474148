import { PureComponent, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'
import Header from './components/header'
import {
    HomepageTopSection,
    HomepageTopSectionMain
} from './components/styles/Homepage.styled'
import { Card, CardBody, CardHeader, CardIcon, HeroSection, HeroSectionBg, HeroSectionMain } from './components/styles/Common.styled'
import { fetchDailyUsage, fetchEstUsage, fetchHourlyUsage, fetchMonthlyUsage } from './api/api'
import { MonthlyUsageProps, SmtDailyReads, SmtMonthlyReads } from './types'
import { ProductMain } from './components/styles/Product.styled'
import { Button } from './components/styles/Button.styled'
import { SolarPlan } from './main'
import { LoadingRow } from './components/loading-row'
import { allProducts, getDaysInMonth, months } from './common'
import { QuestionModal } from './components/QuestionModal'
import { SavingSection } from './components/product/savingSection'
import { ValidateAddress } from './validate_address'
import { GetUsageModal } from './components/getUsageModal'
import moment from 'moment'
import { ChartTooltips, ChartWrapper, CompareTableWrapper } from './components/styles/Plan.styled'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { InstallerQuoteForm } from './components/installerQuoteForm'
import { Bar, BarChart, Brush, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

export const Product4Installer = () => {
    const { t } = useTranslation('common');
    const monthName = [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'),
    t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')];
    
    const location = useLocation();
    const {
        addr, esiid, zipcode, city, state, utcode, solar,
        // actualusage, usagefrom
    } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [ usage, setUsage ] = useState<MonthlyUsageProps | undefined>(undefined);
    const [ usageByMonth, setUsageByMonth ] = useState<any>(undefined);
    const [ usageSummaryData, setUsageSummaryData ] = useState<any>(undefined);
    const [ freeWeekendUsage, setFreeWeekendUsage ] = useState(undefined)
    const [ free7DaysUsage, setFree7DaysUsage ] = useState(undefined)
    const [ daynightUsage, setDaynightUsage ] = useState(undefined);
    const [ loading, setLoading ] = useState(true);
    const [ loadingDailyData, setLoadingDailyData ] = useState(false);
    const [ loadingHourlyData, setLoadingHourlyData ] = useState(false);
    const [ products, setProducts ] = useState<any>([]);
    const [ showQuestionModal, setShowQuestionModal ] = useState(false);
    const [ totalUsage, setTotalUsage ] = useState(0);
    const [ total, setTotal ] = useState(0);
    const [ totalRange, setTotalRange ] = useState({ min: 0, max: 0 });
    const [ selectedInverter, setSelectedInverter ] = useState<any>(allProducts.inverter[0]);
    const [ selectedBattery, setSelectedBattery ] = useState<any>(allProducts.battery[0]);
    const [ selectedSolar, setSelectedSolar ] = useState<any>(allProducts.solar[0]);
    const [ showGetUsageModal, setShowGetUsageModal ] = useState(false);
    const [ isEstMode, setIsEstMode ] = useState(true);
    const [ consentId, setConsentId ] = useState('');
    const [ peakDailyUsage, setPeakDailyUsage ] = useState<any>(undefined);
    // const [ cookies, setCookie, removeCookie ] = useCookies(['consent_id']);
    
    useEffect(() => {
        if (!addr || !esiid || !zipcode || !city || !state || !utcode) return;

        getEstUsage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addr, city, state, zipcode])

    useEffect(() => {
        if (!usage) return;

        getProduct();
        setTotalUsage(Object.keys(usage).reduce((accumulator: number, currentValue: string) => accumulator + parseInt(usage[currentValue]), 0));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usage])

    const getEstUsage = () => {
        setLoading(true);
        setUsage(undefined);
        setPeakDailyUsage(undefined);
        fetchEstUsage(addr, city, state, zipcode).then((res) => {
            if (res && res.status === 1) {
                const { usages } = res;

                const usageArr: number[] = Object.values(usages);
                const maxKey = usageArr.reduce(function (maxIndex, currentValue, currentIndex, array): number {
                    if (currentValue > array[maxIndex]) {
                        return currentIndex;
                    } else {
                        return maxIndex;
                    }
                }, 0);
                const peakUsage: number = usageArr[maxKey];
                const peakUsageDaily = peakUsage / getDaysInMonth(maxKey + 1);

                setUsage(usages);
                setPeakDailyUsage({
                    month: months[maxKey],
                    value: peakUsageDaily
                });
            }
        });
    }

    useEffect(() => {
        if (!usageByMonth) return;
        const result: any = {};
        for (const key in usageByMonth) {
            const monthData = usageByMonth[key];
            const total = monthData.reduce((sum: number, value: number) => sum + value, 0);
            const average = total / monthData.length;
            const max = Math.max(...monthData);
            const min = Math.min(...monthData);
            const aboveAverage = monthData.filter((value: number) => value > average).length;
            result[key] = {
                month: key,
                total,
                average,
                max,
                min,
                aboveAverage,
                days: monthData.length
            };
        }
        setUsageSummaryData(result);
    }, [usageByMonth])

    const getMonthlyUsage = () => {
        setLoading(true)
        setUsage(undefined)
        const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
        const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
        fetchMonthlyUsage(esiid, consentId, startDate, endDate).then((res) => {
            if (res && res.response && res.response.reads) {
                const monthlyUsage = mergeMonthlyReads(res.response.reads)
                setUsage(monthlyUsage)
                setIsEstMode(false)
            }
        })
    }

    const mergeMonthlyReads = (data: Array<SmtMonthlyReads>) => {
        let usage: any = {}
        for (let i in data) {
            const key = moment(data[i].end_date, 'MM/DD/YYYY').format('M')
            usage[key] = usage[key]
                ? usage[key] + parseInt(data[i].actual_kwh)
                : parseInt(data[i].actual_kwh)
        }
        return usage
    }

    const getDailyUsage = () => {
        setLoadingDailyData(true)
        setFreeWeekendUsage(undefined)
        setFree7DaysUsage(undefined)
        if (consentId) {
            const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
            const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
            fetchDailyUsage(esiid, consentId, startDate, endDate).then((res) => {
                setLoadingDailyData(false)
                if (res && res.response && res.response.reads) {
                    const data = mergeDailyReads(res.response.reads)
                    setFree7DaysUsage(data.free7Days)
                    setFreeWeekendUsage(data.freeWeekend)
                    setUsageByMonth(data.dailyUsageByMonth);
                }
            })
        }
    }

    const getHourlyUsage = () => {
        setLoadingHourlyData(true)
        setDaynightUsage(undefined)
        if (consentId) {
            const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY')
            const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY')
            fetchHourlyUsage(esiid, consentId, startDate, endDate).then((res) => {
                setLoadingHourlyData(false)
                if (res && res.response && res.response.reads) {
                    const data = mergeHourlyReads(res.response.reads)
                    setDaynightUsage(data)
                }
            })
        }
    }

    const mergeDailyReads = (data: Array<SmtDailyReads>) => {
        let freeWeekend: any = {},
            free7Days: any = {},
            dailyUsage: any = [],
            dailyUsageByMonth: any = {};

        for (let i in data) {
            const date = moment(data[i].read_date, 'MM/DD/YYYY')
            const m = date.format('M')
            const w = date.days()
            const objKey = w === 0 || w === 6 ? 'free' : 'charge'
            const usage = parseFloat(data[i].energy_data_kwh)

            if (!dailyUsage[m]) dailyUsage[m] = []
            if (!freeWeekend[m]) freeWeekend[m] = {}

            dailyUsage[m].push(usage)
            freeWeekend[m][objKey] = freeWeekend[m][objKey] ? freeWeekend[m][objKey] + usage : usage
        }

        for (let m in dailyUsage) {
            const oneMonth = dailyUsage[m]
            oneMonth.sort((a: string, b: string) => {
                if (parseInt(a) < parseInt(b)) return 1
                if (parseInt(a) > parseInt(b)) return -1
                return 0
            })
            dailyUsageByMonth[m] = oneMonth;
            oneMonth.forEach((val: number, idx: number) => {
                const objKey = idx < 7 ? 'free' : 'charge'
                if (!free7Days[m]) free7Days[m] = {}
                free7Days[m][objKey] = free7Days[m][objKey] ? free7Days[m][objKey] + val : val
            })
        }

        return {
            freeWeekend: freeWeekend,
            free7Days: free7Days,
            dailyUsageByMonth: dailyUsageByMonth
        }
    }

    const mergeHourlyReads = (data: Array<any>) => {
        let result: any = [];

        let date = '';
        let daytimeSum = 0,
            nighttimeSum = 0;

        for (let key in data) {
            const reads = data[key]
            if (date !== moment(reads.datetime).format('MM/DD/YYYY')) {
                if (date !== '') {
                    result.push({
                        date: date,
                        daytimeSum: daytimeSum,
                        nighttimeSum: nighttimeSum
                    })
                }
                date = moment(reads.datetime).format('MM/DD/YYYY');
                daytimeSum = 0;
                nighttimeSum = 0;
            }

            const hour = moment(reads.datetime).hour();

            if (hour >= 7 && hour < 18) {
                daytimeSum += parseFloat(reads.usage);
            }
            else {
                nighttimeSum += parseFloat(reads.usage);
            }
            if (parseInt(key) === data.length - 1) {
                result.push({
                    date: date,
                    daytimeSum: daytimeSum,
                    nighttimeSum: nighttimeSum
                })
            }
        }

        return result
    }

    useEffect(() => {
        if (products.length === 0 || !selectedInverter || !selectedBattery || !selectedSolar) return;
        const { battery, panel } = products[0];
        setTotal(battery * selectedBattery.price + panel * selectedSolar.price + selectedInverter.price);

    }, [products, selectedInverter, selectedBattery, selectedSolar])

    const getProduct = () => {
        if (!usage) return;
        
        const usageArr = Object.values(usage);
        const newCal = new SolarPlan(usageArr);

        let fetchArr = [];

        switch (solar) {
            case '0': fetchArr.push(newCal.getSolarBatteryPlan()); break;
            case '1': fetchArr.push(newCal.getBatteryOnlyPlan()); break;
            case '2': fetchArr.push(newCal.getSolarOnlyPlan()); break;
            default: fetchArr.push(newCal.getSolarBatteryPlan(), newCal.getBatteryOnlyPlan(), newCal.getSolarOnlyPlan());
        }

        Promise.all(fetchArr).then((res: any) => {
            setLoading(false);
            setProducts(res);
        }).catch(err => {
            setLoading(false);
            console.error(err);
        });
    }

    return (
        <>
            <Header />
            <HeroSection>
                <HeroSectionMain>
                    <Container>
                        <HomepageTopSection>
                            <HomepageTopSectionMain>
                                <h1>Energy Freedom Starts with Unblock Solar</h1>
                                <p>Contact us to learn more about our affordable and reliable off-grid energy solutions</p>
                                <ValidateAddress
                                    handleConfirm={(url: string) => {
                                        if (url) {
                                            window.location.href = `${window.location.origin}/super${url}`;
                                        }
                                    }}
                                />
                            </HomepageTopSectionMain>
                        </HomepageTopSection>
                    </Container>
                </HeroSectionMain>
                <HeroSectionBg><img src="/images/solar-panels-2022-11-11-08-53-10-utc.jpg" alt="" /></HeroSectionBg>
            </HeroSection>
            {
                usage &&
                <ProductMain>
                    <Container>
                        <Row>
                            <Col md={12} lg={12}>
                                <Card>
                                    <CardHeader>
                                        <CardIcon type="setup" />
                                        <div>
                                            <h3>Usage Summary</h3>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {
                                            loadingDailyData ?
                                            <div className="pt-5 pb-5"><LoadingRow /></div> :
                                            (
                                                usageSummaryData ?
                                                <CompareTableWrapper>
                                                    <table width="100%">
                                                        <thead>
                                                            <tr>
                                                                <th>Month</th>
                                                                <th>Usage</th>
                                                                <th>Average Day Usage</th>
                                                                <th>Max Day kWh</th>
                                                                <th>Min Day kWh</th>
                                                                <th>Days higher than average</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Object.keys(usageSummaryData).map((key, idx) => {
                                                                    const data = usageSummaryData[key];
                                                                    return (
                                                                        <tr key={idx}>
                                                                            <td data-days={data.days}>{monthName[idx]}</td>
                                                                            <td><NumberFormat value={data.total} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix=' kWh' /></td>
                                                                            <td><NumberFormat value={data.average} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix=' kWh' /></td>
                                                                            <td><NumberFormat value={data.max} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix=' kWh' /></td>
                                                                            <td><NumberFormat value={data.min} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix=' kWh' /></td>
                                                                            <td><NumberFormat value={data.aboveAverage} displayType={'text'} /></td>
                                                                        </tr>
                                                                    )}
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </CompareTableWrapper> :
                                                <div className="pt-5 pb-5 text-center">
                                                    <Button className="mb-3" onClick={() => setShowGetUsageModal(true)}>Get Usage</Button>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                </div>
                                            )
                                        }
                                    </CardBody>
                                </Card>
                                {
                                    !isEstMode &&
                                    <Card>
                                        <CardHeader>
                                            <CardIcon type="usage" />
                                            <h3>Usage Insight</h3>
                                        </CardHeader>
                                        <CardBody>
                                            {
                                                loadingHourlyData ?
                                                <div className="pt-5 pb-5"><LoadingRow /></div> :
                                                <UsageInsightChart data={daynightUsage} />
                                            }
                                        </CardBody>
                                    </Card>
                                }
                                {
                                    !isEstMode &&
                                    <Card>
                                        <SavingSection
                                            loading={loading}
                                            totalUsage={totalUsage}
                                            total={total}
                                            totalRange={totalRange}
                                        />
                                    </Card>
                                }
                                {/* <InstallerQuoteForm /> */}
                            </Col>
                        </Row>
                    </Container>
                </ProductMain>
            }
            <GetUsageModal
                show={showGetUsageModal}
                handleClose={() => {
                    setShowGetUsageModal(false)
                }}
                handleSetConsentId={(id: string) => {
                    setConsentId(id)
                    // setCookie('consent_id', id, { path: '/', maxAge: 3600 })
                }}
                handleFetchActualUsage={() => {
                    getMonthlyUsage();
                    getDailyUsage();
                    getHourlyUsage();
                }}
                zipcode={`${zipcode}`}
                defaultView="smt"
            />
            <QuestionModal
                show={showQuestionModal}
                handleClose={() => {
                    setShowQuestionModal(false);
                }}
                handleConfirm={(val: string) => {
                    const urlParams = new URLSearchParams(window.location.search);
                    urlParams.set('solar', val.split('solar=')[1]);
                    const newQueryString = urlParams.toString();
                    window.location.href = `${window.location.origin}${window.location.pathname}?${newQueryString}`;
                }}
                defaultValue={{ product: solar }}
            />
        </>
    )
}

const tooltipStyles = {
    cursor: {
        fill: 'transparent'
    },
}

const UsageInsightChart = ({ data }: {
    data: undefined|Array<{
        date: string;
        daytimeSum: number;
        nighttimeSum: number;
    }>
}) => {
    if (!data) return null;

    return (
        <ChartWrapper>
            <ResponsiveContainer debounce={300}>
                <BarChart
                    data={data}
                    margin={{top: 24, bottom: 24}}
                >
                    <XAxis dataKey="date" tick={{fontSize:10, fill: '#bbb'}} />
                    {/* <YAxis
                        stroke="#fff"
                        tick={{fontSize:10, fill: '#bbb'}}
                        tickFormatter={(tickItem) => {
                            return tickItem
                        }}
                    /> */}
                    <Tooltip
                        { ... tooltipStyles }
                        position={{ y: 50 }}
                        wrapperStyle={{ zIndex: 1 }}
                        content={ <CustomTooltip /> }
                    />
                    <CartesianGrid vertical={false} strokeDasharray="2" />
                    <Bar
                        dataKey="nighttimeSum"
                        stackId="day"
                        fill="#354D2E"
                        isAnimationActive={true}
                        maxBarSize={80}
                        animationDuration={1000}
                    />
                    <Bar
                        dataKey="daytimeSum"
                        stackId="day"
                        fill="#5FB149"
                        isAnimationActive={true}
                        maxBarSize={80}
                        animationDuration={1000}
                    />
                    <Brush startIndex={300} endIndex={data.length - 1} dataKey="date" />
                </BarChart>
            </ResponsiveContainer>
        </ChartWrapper>
    )
}

class CustomTooltip extends PureComponent {
    render() {
        const { active, payload }: Readonly<any> = this.props;
        
        if (!active || !payload || !payload[0]) return null;
        const { date, daytimeSum, nighttimeSum } = payload[0].payload;
        return (
            <ChartTooltips>
                <h6>{date}</h6>
                <p>Day: {Math.round(daytimeSum * 100) / 100} kWh</p>
                <p>Night: {Math.round(nighttimeSum * 100) / 100} kWh</p>
            </ChartTooltips>
        )
    }
}