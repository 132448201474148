import styled from 'styled-components';

export const HeroSection = styled.div`
    background: rgb(5,23,3);
    background: linear-gradient(0deg, rgba(5,23,3,0.85) 0%, rgba(5,23,3,1) 100%);
    padding-top: 100px;
    padding-bottom: 60px;
    position: relative;
`

export const HeroSectionBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .4;

    video, img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const HeroSectionMain = styled.div`
    position: relative;
    z-index: 1;
`

export const Card = styled.div`
    margin: 24px 0;
    border: 1px solid #eee;
    background-color: #fff;
    border-radius: 8px;
`

export const CardHeader = styled.div`
    padding: 16px 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;

    h3 {
        font-weight: 600;
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 4px;
    }

    p {
        margin-bottom: 0;
        font-size: 14px;
        opacity: .6;
        line-height: 1.1;
    }
`

export const CardBody = styled.div`
    padding: 16px 20px;
`

interface ServiceIconProps {
    type: string;
}

const cardIconSrc = ({ type }: ServiceIconProps) => {
    if (type === 'setup') return 'bolt.svg';
    if (type === 'usage') return 'chart-column.svg';
    if (type === 'saving') return 'calculator.svg';
    if (type === 'clipboard') return 'clipboard.svg';
    return '';
}

export const CardIcon = styled.div<ServiceIconProps>`
    width: 24px;
    height: 24px;
    margin-right: 16px;
    mask: url('/images/icons/${cardIconSrc}') 0 0 no-repeat;
    mask-size: cover;
    background-color: #F06E2E;
    flex-shrink: 0;
`