import { useEffect, useState } from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import Header from "./components/header"
import { Button } from "./components/styles/Button.styled"
import Select from 'react-select'
import { QuoteSection, QuoteInto, QuoteForm, HomepageTopSection, HomepageTopSectionMain } from './components/styles/Homepage.styled'
import { HeroSection, HeroSectionBg, HeroSectionMain } from "./components/styles/Common.styled"
import { ProductMain } from "./components/styles/Product.styled"
import { useLocation } from "react-router"
import qs from 'qs'
import { serviceOptions } from "./common"

const selectStyles = {
    control: (style: object) => ({
        ...style,
        borderColor: '#ced4da',
        borderRadius: 4
    }),
    singleValue: (style: object) => ({
        ...style,
        color: '#495057',
        fontSize: 16
    }),
    valueContainer: (style: object) => ({
        ...style,
        padding: '8px 6px'
    })
}

export const Quote = () => {
    const location = useLocation();
    const { addr } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [ address, setAddress ] = useState('');
    const [ selectedTopic, setSelectedTopic ] = useState(undefined);

    useEffect(() => {
        if (addr) {
            setAddress(`${addr}`);
        }
    }, [addr])

    const handleChangeInput = () => {
        
    }

    return (
        <>
            <Header />
            <HeroSection>
                <HeroSectionMain>
                    <Container>
                        <HomepageTopSection>
                            <HomepageTopSectionMain>
                                <h1>Get a free quote</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </HomepageTopSectionMain>
                        </HomepageTopSection>
                    </Container>
                </HeroSectionMain>
                <HeroSectionBg><img src="/images/solar-panels-2022-11-11-08-53-10-utc.jpg" alt="" /></HeroSectionBg>
            </HeroSection>
            <ProductMain className="mb-5">
                <Container>
                    <QuoteSection className="justify-content-center">
                        <QuoteForm>
                            <Form.Group>
                                <Form.Label>Let us know what you're after</Form.Label>
                                <Select
                                    instanceId="topicSelect"
                                    value={selectedTopic}
                                    onChange={(obj: any) => {
                                        setSelectedTopic(obj);
                                    }}
                                    options={serviceOptions}
                                    getOptionLabel={option => `${option.label}`}
                                    getOptionValue={option => `${option.value}`}
                                    isSearchable={true}
                                    maxMenuHeight={200}
                                    styles={selectStyles}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name="first_name"
                                            placeholder="First Name"
                                            maxLength={20}
                                            // value={inputValue[val].value}
                                            // onChange={handleChangeInput}
                                            // disabled={loading}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name="last_name"
                                            placeholder="Last Name"
                                            maxLength={20}
                                            // value={inputValue[val].value}
                                            // onChange={handleChangeInput}
                                            // disabled={loading}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    maxLength={50}
                                    // value={inputValue[val].value}
                                    // onChange={handleChangeInput}
                                    // disabled={loading}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    maxLength={50}
                                    value={address}
                                    onChange={handleChangeInput}
                                    // disabled={loading}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    placeholder="Phone Number"
                                    maxLength={20}
                                    // value={inputValue[val].value}
                                    // onChange={handleChangeInput}
                                    // disabled={loading}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    placeholder="Message"
                                    rows={4}
                                />
                            </Form.Group>
                            <Button>Submit</Button>
                        </QuoteForm>
                    </QuoteSection>
                </Container>
            </ProductMain>
        </>
    )
}