import React, { useState, useEffect } from 'react';
// import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';
import { Button } from './components/styles/Button.styled';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import type { Address, Utilities } from './types';
import { fetchAddress, fetchUtility } from './api/api';
import { Dropdown } from 'react-bootstrap';

export const ValidateAddress = ({ defaultAddress, handleConfirm }: {
    defaultAddress?: string;
    handleConfirm: Function;
}) => {
    const { t } = useTranslation('homepage');
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);
    const [ addressOptions, setAddressOptions ] = useState<Address[]>([]);
    const [ selectedAddress, setSelectedAddress ] = useState<Address[]>([]);
    const [ loadingUtility, setLoadingUtility ] = useState(false);
    const [ utility, setUtility ] = useState<Utilities[]>([]);
    const [ selectedUtility, setSelectedUtility ] = useState<Utilities | undefined>(undefined);
    // const [ cookies, setCookie, removeCookie ] = useCookies(['consent_id']);

    useEffect(() => {
        const _address = defaultAddress ? defaultAddress : localStorage.getItem('saved_address');
        if (_address) {
            fetchAddress(_address).then(data => {
                if (data && data.length > 0) {
                    setSelectedAddress(data);
                }
            })
        }
    }, []);

    useEffect(() => {
        if (selectedAddress.length === 0) return;
        setLoadingUtility(true);
        const { zipcode } = selectedAddress[0];
        fetchUtility(zipcode).then(res => {
            setLoadingUtility(false);
            if (res) {
                setUtility(res);
                setSelectedUtility(res[0]);
            }
        })
    }, [selectedAddress])

    const handleSearch = (val:string) => {
        setLoading(true);
        fetchAddress(val).then(data => {
            setAddressOptions(data);
            setLoading(false);
        })
    }

	const handleClick = () => {
        if (selectedAddress.length === 0) return;
        const address = selectedAddress[0];
        localStorage.setItem('saved_address', address.street);

        const { street, esiid, zipcode, city, state } = address;
        const utilityCode = selectedUtility ? selectedUtility.utility_code : '';

        handleConfirm(`?addr=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&source=${''}&utcode=${utilityCode}`);
        // redirectTo();
	}

    const redirectTo = (type?: string) => {
        const address = selectedAddress[0];
        const { street, esiid, zipcode, city, state } = address;
        const utilityCode = selectedUtility ? selectedUtility.utility_code : '';
        // if (type === 'plan') {
        //     if (cookies) {
        //         removeCookie('consent_id');
        //     }
        //     navigate(`/plans?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utcode=${utilityCode}`);
        // }
        // else {
        //     navigate(`/get_usage?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&source=${type}&utcode=${utilityCode}`);
        // }

        window.location.href = `/product?addr=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&source=${''}&utcode=${utilityCode}`;
        // navigate(`/product?addr=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&source=${type}&utcode=${utilityCode}`, { replace: true });
    }

    const chooseUtility = () => {
        if (utility.length <= 1 || !selectedUtility) return null;
        return (
            <Dropdown className="utility-select">
                <Dropdown.Toggle variant="light">
                    {selectedUtility.utility_name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        utility.map((val, idx) => (
                            <Dropdown.Item
                                eventKey={idx}
                                key={idx}
                                onClick={() => {
                                    setSelectedUtility(val);
                                }}
                                active={val.utility_id === selectedUtility.utility_id}
                            >{val.utility_name}</Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <div>
            <div className="autocomplete-address-row">
                <div id="autocomplete-wrapper">
                    <AsyncTypeahead
                        filterBy={() => true}
                        id="autocomplete"
                        isLoading={loading}
                        labelKey="label"
                        minLength={1}
                        onSearch={handleSearch}
                        options={addressOptions}
                        placeholder={`${t('Address')}...`}
                        size="lg"
                        onChange={setSelectedAddress}
                        selected={selectedAddress}
                    />
                </div>
                { chooseUtility() }
            </div>
            <Button
                onClick={handleClick}
                disabled={!selectedAddress[0] || loadingUtility}
                className={css`
                    margin-top: 20px;
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                `}
            >
                <span>{t('Get Started')}</span>
            </Button>
        </div>
    )
}