import { useEffect, useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'
import Header from './components/header'
import {
    HomepageTopSection,
    HomepageTopSectionMain
} from './components/styles/Homepage.styled'
import { Card, CardBody, CardHeader, CardIcon, HeroSection, HeroSectionBg, HeroSectionMain } from './components/styles/Common.styled'
import { fetchEstUsage } from './api/api'
import { MonthlyUsageProps } from './types'
import { ProductList, ProductMain } from './components/styles/Product.styled'
import { Button } from './components/styles/Button.styled'
import { SolarPlan } from './main'
import { LoadingRow } from './components/loading-row'
import { SALESTAX, allProducts, getDaysInMonth, laborCost, months, partsCost } from './common'
import { QuestionModal } from './components/QuestionModal'
import { ProductItem } from './components/product/productItem'
import { ProductAside } from './components/product/productAside'
import { SavingSection } from './components/product/savingSection'

export const Product = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        addr, esiid, zipcode, city, state, utcode, solar, 
        // actualusage, usagefrom
    } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [ usage, setUsage ] = useState<MonthlyUsageProps | undefined>(undefined);
    const [ loading, setLoading ] = useState(true);
    const [ products, setProducts ] = useState<any>([]);
    const [ showQuestionModal, setShowQuestionModal ] = useState(false);
    const [ totalUsage, setTotalUsage ] = useState(0);
    const [ total, setTotal ] = useState(0);
    const [ totalRange, setTotalRange ] = useState({ excludeTaxMin: 0, excludeTaxMax: 0, min: 0, max: 0 });
    const [ selectedInverter, setSelectedInverter ] = useState<any>(allProducts.inverter[0]);
    const [ selectedBattery, setSelectedBattery ] = useState<any>(allProducts.battery[0]);
    const [ selectedSolar, setSelectedSolar ] = useState<any>(allProducts.solar[0]);
    const [ peakDailyUsage, setPeakDailyUsage ] = useState<any>(undefined);
    const [ selectedOption, setSelectedOption ] = useState(0);
    // const [ cookies, setCookie, removeCookie ] = useCookies(['consent_id']);
    
    useEffect(() => {
        if (!addr || !esiid || !zipcode || !city || !state || !utcode) return;

        getEstUsage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addr, city, state, zipcode])

    useEffect(() => {
        if (!usage) return;

        getProduct();
        setTotalUsage(Object.keys(usage).reduce((accumulator: number, currentValue: string) => accumulator + parseInt(usage[currentValue]), 0));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usage])

    const getEstUsage = () => {
        setLoading(true);
        setUsage(undefined);
        setPeakDailyUsage(undefined);
        fetchEstUsage(addr, city, state, zipcode).then((res) => {
            if (res && res.status === 1) {
                const { usages } = res;

                const usageArr: number[] = Object.values(usages);
                const maxKey = usageArr.reduce(function (maxIndex, currentValue, currentIndex, array): number {
                    if (currentValue > array[maxIndex]) {
                        return currentIndex;
                    } else {
                        return maxIndex;
                    }
                }, 0);
                const peakUsage: number = usageArr[maxKey];
                const peakUsageDaily = peakUsage / getDaysInMonth(maxKey + 1);

                setUsage(usages);
                setPeakDailyUsage({
                    month: months[maxKey],
                    value: peakUsageDaily
                });
            }
        });
    }

    useEffect(() => {
        if (products.length === 0 || !selectedInverter || !selectedBattery || !selectedSolar) return;
        const { battery, panel } = products[0];

        const totalSolarPrice = panel * selectedSolar.price;
        const totalBatteryPrice = battery * selectedBattery.price;
        const totalInverterPrice = selectedInverter.price;
        const productTotal = totalSolarPrice + totalBatteryPrice + totalInverterPrice;

        const _partsRange = (partsCost as any)[selectedOption];
        const _laborRange = (laborCost as any)[selectedOption];
        const costRangeIncludeParts = {
            min: productTotal + _partsRange.min,
            max: productTotal + _partsRange.max
        }
        const costRangeIncludePartsAndTax = {
            min: costRangeIncludeParts.min + costRangeIncludeParts.min * SALESTAX,
            max: costRangeIncludeParts.max + costRangeIncludeParts.max * SALESTAX
        }

        setTotalRange({
            excludeTaxMin: costRangeIncludeParts.min + _laborRange.min,
            excludeTaxMax: costRangeIncludeParts.max + _laborRange.max,
            min: costRangeIncludePartsAndTax.min + _laborRange.min,
            max: costRangeIncludePartsAndTax.max + _laborRange.max
        })

        setTotal(productTotal);
    }, [products, selectedInverter, selectedBattery, selectedSolar, selectedOption])

    const getProduct = () => {
        if (!usage) return;
        
        const usageArr = Object.values(usage);
        const newCal = new SolarPlan(usageArr);

        let fetchArr = [];

        switch (solar) {
            case '0': fetchArr.push(newCal.getSolarBatteryPlan()); break;
            case '1': fetchArr.push(newCal.getBatteryOnlyPlan()); break;
            case '2': fetchArr.push(newCal.getSolarOnlyPlan()); break;
            default: fetchArr.push(newCal.getSolarBatteryPlan(), newCal.getBatteryOnlyPlan(), newCal.getSolarOnlyPlan());
        }

        Promise.all(fetchArr).then((res: any) => {
            setLoading(false);
            setProducts(res);
        }).catch(err => {
            setLoading(false);
            console.error(err);
        });
    }

    return (
        <>
            <Header />
            <HeroSection>
                <HeroSectionMain>
                    <Container>
                        <HomepageTopSection>
                            <HomepageTopSectionMain>
                                <h1>Energy Freedom Starts with Unblock Solar</h1>
                                <p>Contact us to learn more about our affordable and reliable off-grid energy solutions</p>
                            </HomepageTopSectionMain>
                        </HomepageTopSection>
                    </Container>
                </HeroSectionMain>
                <HeroSectionBg><img src="/images/solar-panels-2022-11-11-08-53-10-utc.jpg" alt="" /></HeroSectionBg>
            </HeroSection>
            <ProductMain>
                <Container>
                    <Row>
                        <Col md={12} lg={8}>
                            <Card>
                                <CardHeader>
                                    <CardIcon type="setup" />
                                    <div>
                                        <h3>Recommended Setup</h3>
                                        <p>The recommended setup is based on your consumption results</p>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {
                                            loading ?
                                            <div className="pt-5 pb-5"><LoadingRow /></div> :
                                            <>
                                                <ProductList>
                                                    <Tabs
                                                        defaultActiveKey={selectedOption}
                                                        id="product-tab"
                                                        className={`mt-3 ${products.length <= 1 ? 'hide-tab' : ''}`}
                                                        onSelect={(key) => setSelectedOption(Number(key))}
                                                    >
                                                        {
                                                            products.map((item: any, idx: number) => (
                                                                <Tab eventKey={idx} title={item.label} key={idx} className="mt-4">
                                                                    <ProductItem
                                                                        type={item.type}
                                                                        data={item}
                                                                        totalRange={totalRange}
                                                                        handleShowOptions={() => setShowQuestionModal(true)}
                                                                        selectedInverter={selectedInverter}
                                                                        selectedSolar={selectedSolar}
                                                                        selectedBattery={selectedBattery}
                                                                        handleChangeInverter={(val: any) => {
                                                                            setSelectedInverter(val);
                                                                        }}
                                                                        handleChangeSolar={(val: any) => {
                                                                            setSelectedSolar(val);
                                                                        }}
                                                                        peakDailyUsage={peakDailyUsage}
                                                                    />
                                                                </Tab>
                                                            ))
                                                        }
                                                    </Tabs>
                                                </ProductList>
                                                <div className="text-center mt-4">
                                                    <Button onClick={() => navigate(`/quote?addr=${encodeURIComponent(`${addr}`)}&solar=${solar}`)}>Get Free Quote</Button>
                                                </div>
                                            </>
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <SavingSection
                                    loading={loading}
                                    totalUsage={totalUsage}
                                    total={total}
                                    totalRange={totalRange}
                                />
                            </Card>
                        </Col>
                        <Col md={12} lg={4}>
                            <ProductAside
                                loading={loading}
                                totalUsage={totalUsage}
                                usage={usage}
                                peakDailyUsage={peakDailyUsage}
                                handleShowGetUsageModal={() => {}}
                            />
                        </Col>
                    </Row>
                </Container>
            </ProductMain>
            <QuestionModal
                show={showQuestionModal}
                handleClose={() => {
                    setShowQuestionModal(false);
                }}
                handleConfirm={(val: string) => {
                    const urlParams = new URLSearchParams(window.location.search);
                    urlParams.set('solar', val.split('solar=')[1]);
                    const newQueryString = urlParams.toString();
                    window.location.href = `${window.location.origin}${window.location.pathname}?${newQueryString}`;
                }}
                defaultValue={{ product: solar }}
            />
        </>
    )
}