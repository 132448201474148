import styled from 'styled-components';

export const EnrollmentContainer = styled.div`
    display: flex;
    padding: 24px 0;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`

export const EnrollmentMain = styled.div`
    flex: 1;
`

export const EnrollmentAside = styled.div`
    width: 360px;
    margin-left: 60px;
    
    @media (max-width: 1200px) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 44px;
        margin-top: 44px;
    }
`

export const AsideBox = styled.div`
    background-color: #fafafa;
    border-radius: 4px;
    overflow: hidden;
    padding: 24px 20px;
`

export const AsideBoxHeader = styled.div`
    margin-bottom: 32px;
    
    h5{
        margin: 0;
        font-size: 17px;
    }
`

export const AsideBoxBody = styled.div`

`

export const EnrollmentStepWrapper = styled.div`
    padding: 20px 0;
`

export const EnrollmentStepFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-top: 44px;
`

export const EnrollmentRadioGroup = styled.div`
    display: flex;
    margin: 20px -20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

interface EnrollmentRadioBoxProps {
    isRecommend?: boolean;
}

export const EnrollmentRadioBox = styled.div<EnrollmentRadioBoxProps>`
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 24px 20px;
    cursor: pointer;
    margin: 0 20px;
    position: relative;
    transition: all .3s;
    text-align: center;
    top: 0;

    &::after {
        content: 'Recommended';
        display: ${({ isRecommend }) => isRecommend ? 'inline-block' : 'none'};
        position: absolute;
        top: -5px;
        right: -6px;
        background-color: #F06E2E;
        padding: 2px 4px;
        border-radius: 4px;
        font-size: 12px;
        color: #fff;
        font-weight: 700;
    }

    :hover {
        top: -3px;
        border-color: #5FB149;
        box-shadow: 0 3px 10px rgba(171, 186, 179, .4);
    }

    & > div{
        display: flex;
        align-items: center;
    }

    span {
        font-weight: 500;
    }

    p{
        margin: 8px 0 0;
        font-size: 14px;
        color: #666;
    }

    img {
        width: 100%;
        max-width: 128px;
    }

    @media (max-width: 768px) {
        margin-top: 8px;
        margin-bottom: 8px;
    }
`

export const EnrollmentFormRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 16px -20px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 4px;
        margin-bottom: 4px;
    }
`

interface EnrollmentFormColProps {
    full?: boolean;
}

export const EnrollmentFormCol = styled.div<EnrollmentFormColProps>`
    flex: ${({ full }) => full ? '0 0 100%' : '0 0 50%'};
    padding: 0 20px;

    .react-datepicker-wrapper input[type="text"] {
        width: 100%;
        height: 38px;
        padding: 0 11px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .dropdown button{
        width: 100%;
        border: 1px solid #ced4da;
    }

    .checkbox-item{
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 768px) {
        flex: 0 0 100%;
    }
`

export const EnrollmentAddressWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    background-color: #fafafa;
    padding: 24px 32px;
    border-radius: 4px;
    border: 1px solid #ddd;

    b {
        font-weight: 500;
        margin-right: 6px;
    }

    p{
        margin: 8px 0;
    }

    a{
        display: inline-block;
        text-decoration: none;
        margin-left: 8px;
    }
`

export const CreditCheckSection = styled.div`
    background-color: #fafafa;
    padding: 24px 32px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin: 24px 0;

    h5 {
        margin-bottom: 24px;
    }

    p {
        color: #666;
    }

    p b {
        font-weight: 500;
    }
`

export const ReviewWrapper = styled.div`
    h5 {
        margin: 20px 0 -8px;
        padding: 0 20px;
    }
`

export const ReviewBlock = styled.div`
    padding: 8px 20px;

    h6 {
        margin-bottom: 16px;
        padding-bottom: 8px;
        font-size: 17px;
        border-bottom: 1px solid #eee;
    }
`

export const ReviewRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
`

export const ReviewDisclaimer = styled.div`
    margin-top: 32px;
    font-size: 14px;
    color: #666;
    font-weight: 300;
    padding: 0 20px;
`

export const PlanSummaryPromote = styled.div`
    padding-top: 16px;
    font-size: 12px;
    color: #999;
`

interface PlanSummaryRowProps {
    narrow?: boolean;
}

export const PlanSummaryRow = styled.div<PlanSummaryRowProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${({ narrow }) => { return narrow ? '4px 0' : '12px 0' }};
    font-size: 14px;
    line-height: 1.2;

    label {
        font-weight: 500;
    }

    span {
        font-size: 15px;
    }

    b {
        font-size: 20px;
        font-weight: 600;
    }
`