import { Col, Container, Form, Row } from 'react-bootstrap'
import { ValidateAddress } from './validate_address'
import Header from './components/header'
import {
    HomepageTopSection, HomepageTopSectionMain,
    SectionContent, SectionImg, SectionRow,
    TestimonialItem, QuoteSection,
    QuoteInto, QuoteForm,
    FeatureSection, FeatureBox, FeatureIcon,
    SectionWrapper, SectionInner, SectionHeader, SectionBody, SectionFooter, SectionBg,
    ServicesList, ServicesItem, ServiceIcon
} from './components/styles/Homepage.styled'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { HeroSection, HeroSectionBg, HeroSectionMain } from './components/styles/Common.styled'
import { useRef, useState } from 'react';
import { Button } from './components/styles/Button.styled';
import { QuestionModal } from './components/QuestionModal';
import 'swiper/css';

export const Homepage = () => {
    const [ showQuestionModal, setShowQuestionModal ] = useState(false);
    const sliderRef = useRef<any>(null);
    const redirectUrl = useRef('');

    return (
        <>
            <Header />
            <HeroSection>
                <HeroSectionMain>
                    <Container>
                        <HomepageTopSection>
                            <HomepageTopSectionMain>
                                <h1>Say Goodbye to Gridlock with Unblock Solar</h1>
                                <p>Your one-stop-shop for affordable and reliable off-grid solar and battery solutions</p>
                                <ValidateAddress
                                    handleConfirm={(url: string) => {
                                        if (url) {
                                            setShowQuestionModal(true);
                                            redirectUrl.current = url;
                                        }
                                    }}
                                />
                            </HomepageTopSectionMain>
                        </HomepageTopSection>
                    </Container>
                </HeroSectionMain>
                <HeroSectionBg>
                    <video src="/videos/hero-video.mp4" loop autoPlay muted playsInline />
                </HeroSectionBg>
            </HeroSection>
            <Container>
                <FeatureSection>
                    <FeatureBox highlight>
                        <FeatureIcon type="customized" />
                        <h5>Customized Solutions</h5>
                        <p>We design and install customized off-grid energy solutions to meet your unique needs and budget.</p>
                    </FeatureBox>
                    <FeatureBox>
                        <FeatureIcon type="team" />
                        <h5>Experienced Team</h5>
                        <p>Our team of experts has years of experience in designing and installing off-grid energy systems.</p>
                    </FeatureBox>
                    <FeatureBox>
                        <FeatureIcon type="technology" />
                        <h5>Latest Technology</h5>
                        <p>We use the latest technology and industry best practices to ensure that our solutions are reliable and cost-effective.</p>
                    </FeatureBox>
                    <FeatureBox>
                        <FeatureIcon type="satisfaction" />
                        <h5>Customer Satisfaction</h5>
                        <p>We are committed to ensuring customer satisfaction and will work with you every step of the way to ensure that your off-grid energy system meets your expectations.</p>
                    </FeatureBox>
                </FeatureSection>
            </Container>

            <Container>
                <SectionRow reverse>
                    <SectionContent>
                        <h2>About Us</h2>
                        <p>At Unblock Solar, we are passionate about providing affordable and reliable off-grid energy solutions to homeowners and businesses in Texas. We understand the importance of clean and sustainable energy, which is why we specialize in custom solar and battery solutions that help our customers reduce their monthly electricity bills and take control of their energy usage.</p>
                        <p>Our team of experts has years of experience in designing and installing off-grid energy systems. We use the latest technology and industry best practices to ensure that our solutions are reliable and cost-effective. With our solutions, you can enjoy the benefits of clean energy without sacrificing comfort and convenience.</p>
                        <p>Contact us today to learn more about our team and how we can help you achieve your energy goals.</p>
                    </SectionContent>
                    <SectionImg>
                        <img src="/images/workers-installing-solar-panels-on-house-roof-2022-10-17-01-16-16-utc.jpg" alt="" />
                    </SectionImg>
                </SectionRow>
            </Container>

            <SectionWrapper isDark>
                <Container>
                    <SectionInner>
                        <SectionHeader>
                            <h2>Services</h2>
                            <p>We offer a wide range of customized off-grid energy solutions to meet your unique needs and budget. Our services include:</p>
                        </SectionHeader>
                        <SectionBody>
                            <ServicesList>
                                <ServicesItem>
                                    <ServiceIcon type="solar" />
                                    <h5>Off-Grid Solar Installations</h5><p>We design and install customized solar systems that help you take advantage of the abundant sunshine in Texas.</p>
                                </ServicesItem>
                                <ServicesItem>
                                    <ServiceIcon type="battery" />
                                    <h5>Battery Solutions</h5><p>Our battery solutions help you store excess solar energy for use during peak hours and power outages.</p>
                                </ServicesItem>
                                <ServicesItem>
                                    <ServiceIcon type="hybird" />
                                    <h5>Hybrid Solutions</h5><p>Our hybrid solutions combine solar and battery technologies to provide reliable and cost-effective off-grid energy solutions.</p>
                                </ServicesItem>
                                <ServicesItem>
                                    <ServiceIcon type="maintenance" />
                                    <h5>Maintenance and Repairs</h5><p>We offer maintenance and repair services to ensure that your off-grid energy system is always working at peak performance.</p>
                                </ServicesItem>
                            </ServicesList>
                        </SectionBody>
                        <SectionFooter>
                            <Button>Learn More</Button>
                        </SectionFooter>
                    </SectionInner>
                </Container>
            </SectionWrapper>

            <Container>
                <SectionRow>
                    <SectionContent>
                        <h2>Solar + EV Bundle</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </SectionContent>
                    <SectionImg>
                        <img src="/images/electric-car-evs-battery-being-charged-2022-12-15-22-59-53-utc.jpg" alt="" />
                    </SectionImg>
                </SectionRow>
            </Container>
            
            <SectionWrapper style={{borderTop:'1px solid #ddd'}}>
                <Container>
                    <SectionHeader>
                        <h2>What Our Customers Say</h2>
                    </SectionHeader>
                    <SectionBody>
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            modules={[Pagination]}
                            centerInsufficientSlides={true}
                            breakpoints={{
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                }
                            }}
                            ref={sliderRef}
                        >
                            <SwiperSlide>
                                <TestimonialItem>
                                    <div></div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </TestimonialItem>
                            </SwiperSlide>
                            <SwiperSlide>
                                <TestimonialItem>
                                    <div></div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </TestimonialItem>
                            </SwiperSlide>
                            <SwiperSlide>
                                <TestimonialItem>
                                    <div></div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </TestimonialItem>
                            </SwiperSlide>
                            <SwiperSlide>
                                <TestimonialItem>
                                    <div></div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </TestimonialItem>
                            </SwiperSlide>
                        </Swiper>
                    </SectionBody>
                </Container>
            </SectionWrapper>

            <SectionWrapper isDark withBg>
                <Container>
                    <QuoteSection>
                        <QuoteInto>
                            <h2>Ready to take control of your energy usage and reduce your monthly electricity bills?</h2>
                            <p>Contact us today to learn more about our customized off-grid energy solutions.</p>
                        </QuoteInto>
                        <QuoteForm>
                            <h2>Contact Us</h2>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name="first_name"
                                            placeholder="First Name"
                                            maxLength={20}
                                            // value={inputValue[val].value}
                                            // onChange={handleChangeInput}
                                            // disabled={loading}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name="last_name"
                                            placeholder="Last Name"
                                            maxLength={20}
                                            // value={inputValue[val].value}
                                            // onChange={handleChangeInput}
                                            // disabled={loading}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    maxLength={50}
                                    // value={inputValue[val].value}
                                    // onChange={handleChangeInput}
                                    // disabled={loading}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    placeholder="Phone Number"
                                    maxLength={20}
                                    // value={inputValue[val].value}
                                    // onChange={handleChangeInput}
                                    // disabled={loading}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    placeholder="Message"
                                    rows={4}
                                />
                            </Form.Group>
                            <Button>Submit</Button>
                        </QuoteForm>
                    </QuoteSection>
                </Container>
                <SectionBg><img src="/images/home-with-beach-vibes-has-solar-panels-on-rooftop-2022-11-12-09-52-05-utc.jpg" alt="" /></SectionBg>
            </SectionWrapper>
            <QuestionModal
                show={showQuestionModal}
                handleClose={() => {
                    setShowQuestionModal(false);
                    redirectUrl.current = '';
                }}
                handleConfirm={(val: string) => {
                    if (redirectUrl.current) {
                        window.location.href = `/product${redirectUrl.current}&${val}`;
                    }
                }}
            />
        </>
    )
}
